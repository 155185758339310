

import { watch, computed, defineComponent, SetupContext, ref, onMounted, registerRuntimeCompiler } from 'vue';
import { IBenefit, IOwnerRelation, IOwnerType, IProposal, IProposalStatus, IProposalStatusHistory, IPurpose, } from '@/types/Interfaces';
import useProposal from '@/types/Proposal'
import { useRoute } from 'vue-router';
export default defineComponent({
  components: {
  },
  setup() {
    const route = useRoute()
    // const data = ref<IProposal|null>(null);
    
    // const {
    //   fetchBenefits  
    // } = useBenefit();

    // const {
    //   fetchPurposes
    // } = usePurpose();

    // const {
    //   fetchOwnerRelations
    // } = useOwnerRelation();

    // const {
    //   fetchOwnerTypes
    // } = useOwnerType();

    // const {
    //   fetchProposalStatus,
    //   sortStatus,
    //   getChildren
    // } = useProposalStatus();

    
    // const {
    //   getProposal,
    // } = useProposal()

    // const purposeList = ref<IPurpose[]>([]); //fetchPurposes();
    // const ownerRelationList = ref<IOwnerRelation[]>([]);  // fetchOwnerRelations();
    // const ownerTypeList = ref<IOwnerType[]>([]); // fetchOwnerTypes();
    // const benefitList = ref<IBenefit[]>([]);
    // const proposalStatusList = ref<IProposalStatus[]>([]); // fetchProposalStatus();
    // const sortedStatus = ref<Array<any>>([]);
    
    // const validators: { [key: string]: Array<Function> } = {
    //   client_name: [
    //     required
    //   ],
    //   client_visitor_benefit_id: [
    //     required
    //   ],
    //   client_contract_benefit_id: [
    //     required
    //   ],
    //   owner_name: [
    //     required
    //   ],
    //   owner_visitor_benefit_id: [
    //     required
    //   ],
    //   owner_contract_benefit_id: [
    //     required
    //   ],
    //   visitor_benefit_expiration: [
    //     required,
    //   ],
    //   contract_benefit_expiration: [
    //     required
    //   ]
    // }
    // const errors = ref<{ [key: string]: any }>({
    //   client_name: '',
    //   relations: '',
    //   purposes: '',
    //   client_visitor_benefit_id: '',
    //   client_contract_benefit_id: '',
    //   owner_name: '',
    //   owner_types: '',
    //   owner_visitor_benefit_id: '',
    //   owner_contract_benefit_id: '',
    //   visitor_benefit_expiration: '',
    //   contract_benefit_expiration: '',
    // })
    
    
    // const statusHistory = ref<IProposalStatusHistory[]>([]);
    const dataReady = ref(false);
    const id = route.params.id.toString();
    // watch(
    //   () => data,
    //   () => { validate() },
    //   { deep: true }
    // )
    // onMounted(() => {
    //   if (id == 'new') {
    //     // create newpan
    //     dataReady.value = true
    //     data.value = {
    //       client_name: '',
    //       owner_name: '',
    //       purposes: [],
    //       relations: [],
    //       linked_services: [],
    //       client_visitor_benefit_id: null,
    //       client_contract_benefit_id: null,
    //       owner_types: [],
    //       owner_visitor_benefit_id: null,
    //       owner_contract_benefit_id: null,
    //       visitor_benefit_expiration: '',
    //       contract_benefit_expiration: '',
    //       status: null,
    //       history: [],
    //     };
        
    //   } else {
    //     Promise.all([
    //       getProposal(parseInt(id)),
    //     ]).then(([
    //       proposal,
    //     ]) => {
    //       dataReady.value = true;
    //       data.value= proposal;
    //       if (data.value == null) return;
    //       data.value.client_visitor_benefit_id = proposal.client_visitor_benefit.id;
    //       data.value.client_contract_benefit_id = proposal.client_contract_benefit.id;
    //       data.value.owner_visitor_benefit_id = proposal.owner_visitor_benefit.id;
    //       data.value.owner_contract_benefit_id = proposal.owner_contract_benefit.id;
          

    //     })
    //   }
    // })
  

    // onMounted(() => {
    //   alert('hogehgoe')
    // })
    return {
      id, 
      dataReady,
      
    }
  }
})
